export default {
  footer: {
    footerTopLogos: [],
    emailSubscription: false,
    paymentsImages: [
      {
        src: '/images/footer/sukl.png',
        alt: 'SUKL overenie',
        link: 'https://www.sukl.sk/hlavna-stranka/slovenska-verzia/inspekcia/lekarenstvo/internetovy-vydaj-liekov-a-zdravotnickych-pomocok/zoznam-subjektov-vykonavajucich-internetovy-vydaj?page_id=4380',
        width: 100,
        height: 100,
      },
      {
        src: '/images/footer/Saec.png',
        alt: 'SAEC logo',
        link: 'https://www.bezpecnynakup.sk/sk/certifikovane-obchody',
        width: 60,
        height: 81,
      },
    ],
    suklImages: false,
    footerTopAbsoluteImages: [
      {
        src: '/images/footer/most-valuable-company.png',
        alt: 'Najdoverihodnejšia znacka 2024',
        width: 190,
        height: 280,
      },
    ],
    footerTopAbsoluteImagesHeight: 280,
    feedatyWidget: false,
    feedatyMerchantId: '',
    useFooterCertificateBlock: false,
    footerCertificateText: '',
    footerCertificateTextClass: '',
    footerCertificateImages: [],
    flags: true,
    mobileApplications: false,
  },
}
