export default {
  footer: {
    links: [
      {
        url: '/podmienky-internetoveho-vydaja',
        text: 'Podmienky internetového výdaja liekov, zdravotníckych pomôcok a doplnkového sortimentu',
      },
      {
        url: '/zasady-ochrany-osobnych-udajov-v-online-lekarni-dr-max',
        text: 'Zásady ochrany osobných údajov',
      },
      {
        url: '/moznosti-dopravy',
        text: 'Možnosti dopravy',
      },
      {
        url: '/moznosti-platby',
        text: 'Možnosti platby',
      },
    ],
  },
}
