import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
var validateSkCRN = function validateSkCRN(value) {
  var inputDigits = value.split("");
  var checkSum = inputDigits.slice(0, 7).reduce(function (accumulator, digit, index) {
    return accumulator + (8 - index) * +digit;
  }, 0);
  var checkSumRemainder = checkSum % 11;
  var checkDigit = (11 - checkSumRemainder) % 10;
  return +inputDigits[7] === checkDigit;
};
export default defineAppConfig({
  validations: {
    cityMaxLength: 30,
    companyNameMaxLength: 70,
    emailMaxLength: 50,
    firstNameMaxLength: 35,
    lastNameMaxLength: 35,
    streetMaxLength: 40,
    forbiddenWords: [],
    cityAllowedCharacters: function cityAllowedCharacters() {
      return true;
    },
    cityContainsLetter: function cityContainsLetter() {
      return true;
    },
    customerNotesAllowedCharacters: function customerNotesAllowedCharacters() {
      return true;
    },
    firstLastNameAllowedCharacters: function firstLastNameAllowedCharacters(value) {
      return /^[a-zA-Z-'\s\u00C0-\u024F\u1E00-\u1EFF]*$/.test(value);
    },
    streetAllowedCharacters: function streetAllowedCharacters() {
      return true;
    },
    crnValid: function crnValid(value) {
      return validateSkCRN(value);
    },
    crnNumbersOnly: function crnNumbersOnly(value) {
      return /^[0-9]+$/.test(value);
    },
    crnLength: 8,
    passwordForbiddenKeywords: ["heslo"],
    postCodeValid: function postCodeValid(value) {
      return /^[0-9]{3}\s?[0-9]{2}$/.test(value);
    },
    streetValid: function streetValid() {
      return true;
    },
    streetNumberValid: function streetNumberValid(value) {
      return /^[0-9]+(\/?[0-9a-z]+)?$/i.test(value);
    },
    streetNumberContainsNumber: function streetNumberContainsNumber() {
      return true;
    },
    postCodeValidByThreeDigits: function postCodeValidByThreeDigits() {
      return true;
    },
    postCodeValidByForbiddenCode: function postCodeValidByForbiddenCode() {
      return true;
    },
    vatIdValid: function vatIdValid(value) {
      return /^SK[0-9]{10}$/.test(value);
    },
    vatIdAllowedCharacters: function vatIdAllowedCharacters() {
      return true;
    },
    vatIdForbiddenWords: function vatIdForbiddenWords() {
      return true;
    },
    vatIdForbiddenValues: [],
    vatIdStartsWith: function vatIdStartsWith(value) {
      return value.substring(0, 2) === "SK";
    },
    vatIdLength: 12,
    vatIdContainsNumber: function vatIdContainsNumber() {
      return true;
    },
    vatIdMaxLength: 12
  }
});