export default defineAppConfig({
  productRelationshipLoaderPersoo: {
    persooAlternativesEnabled: true,
    persooBasketEnabled: true,
    persooLastViewedEnabled: true,
    persooNotFoundEnabled: true,
    persooPreBasketEnabled: true,
    apiKey: "md6g86b6rbl5iiecqjaph8bq",
    dataLayerName: "dataLayerV2",
    persooEnabled: true,
    persooOfferIdAlternatives: "a754940a32144878ac82b8978020ec8d",
    persooOfferIdBasket: "bfa5637abe9d48479ece315ffc9eaeed",
    persooOfferIdLastViewed: "a05a8acf45ea47578d63089c2226b7da",
    persooOfferIdNotFound: "e9232642e6e24e9e9e5bfe4bdf32db76",
    persooOfferIdPreBasket: "d522936c21d74dc9a696fea25296ec36",
    persooPreBasketLimit: 6,
    persooNotFoundLimit: 6,
    persooName: "persoo"
  }
});