export default {
  inputTypes: {
    crn: 'text',
    vatId: 'text',
  },
  inputModes: {
    crn: 'number',
    vatId: 'number',
  },
}
