const pickupInstructions = [
  {
    icon: 'sms',
    fragments: [{ text: 'Vyzdihnutie je možné po doručení SMS alebo emailu' }],
  },
  {
    icon: 'pharmacy',
    fragments: [{ text: 'Objednávka bude pripravená k vyzdvihnutiu 3 dni' }],
  },
]

export default {
  orderLabel: 'Objednávka do boxu',
  unavailablePickupLabel: 'Produkty nie je možné objednať do tohto boxu',
  selectedPlaceLabel: 'Vybraný box',
  confirmButtonLabel: 'Vyberte box',

  getTitle() {
    return [this.title, this.location.street].filter(Boolean).join(', ')
  },

  getSubtitle() {
    return [this.location.zipCode, this.location.city].filter(Boolean).join(' ')
  },

  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  getReviewAddress() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  pins: {
    enabled: 'pin-boxes-enabled.svg',
    disabled: 'pin-boxes-disabled.svg',
    selected: 'pin-boxes-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/box.svg',
}
